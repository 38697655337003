import React from 'react'
import { Link } from 'react-router-dom';
import { convertDateTime, convertISODateString } from '../../../util/convertDate';
import { createSlug } from '../../../util/createSlug';

export default function CardNoticiasRecentes(props) {
    const { noticias } = props;

    return (
        <aside className="widget widget--sidebar card widget-popular-posts">
            <div className="widget__title card__header">
                <h4>
                    ÚLTIMAS NOTÍCIAS
                </h4>
            </div>
            <div className="widget__content card__content">
                {noticias.loading || noticias.error ? '' :
                    <ul className="posts posts--simple-list">
                        {noticias.payload.map((item, key) => (
                            <li className={`posts__item posts__item--category-${item.id_esporte}`} key={key}>
                                <figure className="posts__thumb">
                                    <Link to={`/noticias/leitura/${item.id}/${createSlug(item.titulo)}`}>
                                        <img src={`${process.env.REACT_APP_SGE_URL}_uploads/${process.env.REACT_APP_ID_ESTABELECIMENTO}/noticias/${item.foto_capa}`}
                                            alt=""
                                            onError={e => { e.target.style = 'display:none;' }} />
                                    </Link>
                                </figure>
                                <div className="posts__inner">
                                    <div className="posts__cat">
                                        <span className="label posts__cat-label">
                                            {item.idEsporte ? item.idEsporte.descricao : ''}
                                        </span>
                                    </div>
                                    <h6 className="posts__title">
                                        <Link to={`/noticias/leitura/${item.id}/${createSlug(item.titulo)}`}>
                                            {item.titulo}
                                        </Link>
                                    </h6>
                                    <time dateTime={convertISODateString(item.created_at * 1000)} className="posts__date">
                                        {convertDateTime(item.created_at * 1000)}
                                    </time>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </aside>
    )
}
