import { useContext, useEffect, useState } from "react";
import GridNoticias from "./sections/GridNoticias";
import RedesSociaisAside from "../../components/RedesSociaisAside";
import { ConfedApi, gestaoApi, portalApi } from "../../services/api";
import Classificacao from "./sections/Classificacao";
import JogoHeader from "./sections/JogoHeader";
import NoticiasRecentes from "./sections/NoticiasRecentes";
import ProximaPartida from "./sections/ProximaPartida";
import ResultadosAside from "./sections/ResultadosAside";
import moment from "moment";
import BannerInscricao from "./sections/BannerInscricao";
import VideosYoutube from "./sections/VideosYoutube";
import MetaContext from "../../context/MetaContext";
import _ from "lodash";
import { useMediaQuery } from "@mui/material";
import GeniusSportsWidget from "./sections/GeniusSportsWidget";
import AdsComponent from "./sections/AdsComponent";

export default function HomePage() {
  const { siteMeta } = useContext(MetaContext);

  const [noticias, setNoticias] = useState({});

  useEffect(() => {
    setNoticias({
      loading: true,
      error: false,
      payload: [],
    });
    async function getNoticias() {
      await portalApi
        .get("noticia", {
          params: {
            flag_del: 0,
            flag_publicado: 1,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            expand: "idEsporte,user,idPessoa",
            notNullField: "foto_capa",
            sort: "-created_at",
            lessField: "created_at",
            lessValue: new Date().getTime().toString().substr(0, 10),
            pageSize: 14,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setNoticias({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setNoticias({
              loading: false,
              error: false,
              payload: response.data.items,
            });
            // response.data.items.slice(4, 8)
          }
        })
        .catch((err) => {
          setNoticias({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getNoticias();
  }, []);

  const [resultados, setResultados] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setResultados({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEventoPartida() {
      await ConfedApi.get("evento-partida", {
        params: {
          forcewhere: `idEvento.id_estabelecimento:${process.env.REACT_APP_ID_ESTABELECIMENTO}`,
          forcejoin: "idEvento,casa,visitante",
          expand: "idEvento,casa,visitante",
          notNullField: "placar_final_casa",
          sort: "-data",
          pageSize: 10,
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setResultados({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setResultados({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setResultados({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEventoPartida();
  }, []);

  const [idEvento, setIdEvento] = useState("");

  const [eventos, setEventos] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setEventos({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEventos() {
      await gestaoApi
        .get("evento", {
          params: {
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            flag_del: 0,
            id_tipo: 1,
            sort: "-data_inicio",
            betweenField: "data_inicio",
            betweenValue1: moment().startOf("year").format("YYYY-MM-DD"),
            betweenValue2: moment().format("YYYY-MM-DD")
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEventos({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            let _eventos = response.data.items;
            if (process.env.REACT_APP_DOMAIN === "CBFutsal") {
              const ordemDesc = _.orderBy(response.data.items, ["descricao"], ["asc"])
              //define a ordem 0 para o evento liga paulista 2023
              const orders = ordemDesc.map((item, key) => {
                if (item.id === 83) {
                  return (({ ...item, ordem: 0 }))
                }
                return (({ ...item, ordem: key + 1 }))
              })
              const res = _.orderBy(orders, ["ordem"], ["asc"])
              _eventos = res
            }

            setEventos({
              loading: false,
              error: false,
              payload: _eventos,
            });
            setIdEvento(_eventos[0].id);
          }
        })
        .catch((err) => {
          setEventos({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEventos();
  }, []);

  const handleChangeIdEvento = (event) => {
    setIdEvento(event.target.value);
  };

  const [eventoClassificacao, setEventoClassificacao] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    if (idEvento) {
      setEventoClassificacao({
        loading: true,
        error: false,
        payload: [],
      });
      async function getEventoClassificacao() {
        await ConfedApi.get("evento-grupo", {
          params: {
            forcejoin: "eventoGrupoClubes,eventoClassificacaos",
            forcewhere: `evento_grupo.id_evento:${idEvento}`,
            pageSize: 100,
            expand:
              "eventoGrupoClubes,eventoGrupoClubes.estabelecimento,eventoClassificacaos,eventoClassificacaos.idEstabelecimento,eventoPartidas,eventoPartidas.casa,eventoPartidas.visitante,eventoPartidas.placarPenaltiVisitante,eventoPartidas.placarPenaltiCasa,eventoClassificacaos.eventoPunicaoPontos,equipe",
          },
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setEventoClassificacao({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setEventoClassificacao({
                loading: false,
                error: false,
                payload: response.data.items,
              });
            }
          })
          .catch((err) => {
            setEventoClassificacao({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getEventoClassificacao();
    }
  }, [idEvento]);

  const [partidasDestaque, setPartidasDestaque] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setPartidasDestaque({
      loading: true,
      error: false,
      payload: [],
    });
    async function getPartidasDestaque() {
      await ConfedApi.get("evento-partida", {
        params: {
          // flag_del: 0,
          forcewhere: `idEvento.id_estabelecimento:${process.env.REACT_APP_ID_ESTABELECIMENTO}`,
          forcejoin: "idEvento,casa,visitante",
          expand: "idEvento,casa,visitante",
          sort: "-data",
          lessField: "data",
          lessValue: `${moment(new Date()).format().substr(0, 10)} 23:59:59`,
          moreField: "data",
          moreValue: `${moment(new Date()).format().substr(0, 10)} 00:00:00`,
          pageSize: 10,
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setPartidasDestaque({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setPartidasDestaque({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setPartidasDestaque({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getPartidasDestaque();
  }, []);

  const [proximasPartidas, setProximasPartidas] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setProximasPartidas({
      loading: true,
      error: false,
      payload: [],
    });
    async function getProximasPartidas() {
      await ConfedApi.get("evento-partida", {
        params: {
          // flag_del: 0,
          forcewhere: `idEvento.id_estabelecimento:${process.env.REACT_APP_ID_ESTABELECIMENTO}`,
          forcejoin: "idEvento,casa,visitante",
          expand: "idEvento,casa,visitante",
          sort: "data",
          moreField: "data",
          moreValue: `${moment(new Date()).format().substr(0, 10)} 23:59:59`,
          pageSize: 5,
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount !== 0) {
            setProximasPartidas({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setProximasPartidas({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getProximasPartidas();
  }, []);

  const [artilheiros, setArtilheiros] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    if (idEvento) {
      setArtilheiros({
        loading: true,
        error: false,
        payload: [],
      });
      async function getArtilheiros() {
        await ConfedApi.get("evento/estatisticas-atleta-destaque", {
          params: {
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            idEvento: idEvento,
            idScout: 1,
            sort: "colocacao",
            pageSize: 10,
          },
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setArtilheiros({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setArtilheiros({
                loading: false,
                error: false,
                payload: response.data.items,
              });
            }
          })
          .catch((err) => {
            setArtilheiros({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getArtilheiros();
    }
  }, [idEvento]);

  const matchesMD = useMediaQuery("(max-width:991px)");

  const [linkTwitter, setLinkTwitter] = useState("");
  useEffect(() => {
    if (siteMeta.redesSociais) {
      let twOBJ = _.filter(siteMeta.redesSociais, function (o) {
        return o.name === "twitter";
      });
      setLinkTwitter(twOBJ[0] ? twOBJ[0].url : "");
    }
  }, [siteMeta]);

  return (
    <div>
      {process.env.REACT_APP_ESTABELECIMENTO === "CBFutsal" ? (
        <BannerInscricao />
      ) : (
        ""
      )}
      {partidasDestaque.error ? "" : <JogoHeader partidas={partidasDestaque} />}
      {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
        <GeniusSportsWidget />) : null}

      <GridNoticias noticias={noticias} />

      <div className="site-content">
        <div className="container">
          <div className="row">
            <div className="content col-lg-8">
              <NoticiasRecentes noticias={noticias} />
              {!matchesMD && process.env.REACT_APP_YOUTUBE_UPLOADS_ID && (
                <VideosYoutube />
              )}
            </div>
            <div id="sidebar" className="sidebar col-lg-4">
              <AdsComponent />
              <ResultadosAside resultados={resultados} />
              <Classificacao
                eventoClassificacao={eventoClassificacao}
                eventos={eventos}
                idEvento={idEvento}
                handleChangeIdEvento={handleChangeIdEvento}
                artilheiros={artilheiros}
                tipoCard="evento"
              />
              <RedesSociaisAside />
              {proximasPartidas.loading || proximasPartidas.error ? (
                ""
              ) : (
                <ProximaPartida partidas={proximasPartidas} />
              )}
              {matchesMD && (
                <>
                  {process.env.REACT_APP_YOUTUBE_UPLOADS_ID && (
                    <VideosYoutube />
                  )}
                </>
              )}
              {linkTwitter !== "" && (
                <a
                  className="twitter-timeline"
                  data-height="580"
                  href={`${linkTwitter}?ref_src=twsrc%5Etfw`}
                >
                  Tweets
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
