import React, { useEffect } from 'react'

function AdsComponent() {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, [])

  if (process.env.REACT_APP_ESTABELECIMENTO === "CBHB") {
    return (
      <ins className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-4416035360451877"
        data-ad-slot="2745632232"
        data-ad-format="auto"></ins>
    )
  }
  return null

}

export default AdsComponent