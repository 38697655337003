import favicon from '../assets/images/LPF/favicon.ico';
import logo192 from '../assets/images/LPF/logo192.png';
import logoCBHBTexto from '../assets/images/CBHb/logo_cbhb_texto.png';
import logo from '../assets/images/FAHd/logo.png';
import footerBG from '../assets/images/CBHb/footer_bg.png';
import logoBigmidia from '../assets/images/bigmidia_azul.png'

export const metaFAHd = {
    descricao_estabelecimento: 'Federação Alagoana de Handebol',
    siteTitle: 'FAHd | FEDERAÇÃO ALAGOANA DE HANDEBOL',
    nomeFooter: 'FAHd',
    siteDescription: 'Site Oficial da Federação Alagoana de Handebol.',
    siteImg: logo192,
    logo: logo,
    logoMobile: logo,
    logoTexto: logoCBHBTexto,
    footerBG: footerBG,
    logoBigmidia: logoBigmidia,
    favicon: favicon,
    emailContato: 'sec@cbhb.org.br',
    redesSociais: [
        {
            name: 'facebook',
            url: 'https://www.facebook.com/CBHbOficial/?ref=br_rs',
            btnClass: 'btn-social-counter--fb',
            texto:'Curta nossa página',
        },
        {
            name: 'twitter',
            url: 'https://twitter.com/cbhb1',
            btnClass: 'btn-social-counter--twitter',
            texto:'Siga-nos no Twitter',
        },
        {
            name: 'instagram',
            url: 'https://www.instagram.com/fahdhandebol',
            btnClass: 'btn-social-counter--instagram',
            texto:'Siga-nos no Instagram',
        },
        {
            name: 'youtube',
            url: 'https://www.youtube.com/channel/UCDWbhktmT5WEoVCb9DNY80w',
            btnClass: 'btn-social-counter--fb',
            texto:'',
        }
    ]
}
