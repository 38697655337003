import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { Collapse, Hidden, IconButton, Typography } from "@mui/material";
import _ from "lodash";
import { ConfedApi, governancaApi } from "../services/api";
import { createSlug } from "../util/createSlug";
import lpf_interior from "../assets/images/LPF/lpf_interior.svg";
import lpf_feminina from "../assets/images/LPF/lpf_feminina.svg";
import lpf_sub20 from "../assets/images/LPF/lpf_sub20.svg";
import lpf_junior from "../assets/images/LPF/lpf_junior.svg";
import lpf_kids from "../assets/images/LPF/lpf_kids.svg";
import cbhb_liga_nacional from "../assets/images/CBHb/logo_liga_nacional.svg";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { convertDateTimeAbrev } from "../util/convertDate";
import moment from "moment";
// import PatrocinadoresCBBC from './PatrocinadoresCBBC';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Navbar(props) {
  const { openDrawer, setOpenDrawer, siteMeta, siteMenu } = props;

  const [menuGovernanca, setMenuGovernanca] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setMenuGovernanca({
      loading: true,
      error: false,
      payload: [],
    });
    if (process.env.REACT_APP_ESTABELECIMENTO === "CBBC") {
      async function getMenuGovernanca() {
        await governancaApi
          .get("menu-governanca", {
            params: {
              expand: "filho",
              id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
              isNullField: "id_pai",
              sort: "ordem",
              estado: "ativado",
            },
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setMenuGovernanca({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setMenuGovernanca({
                loading: false,
                error: false,
                payload: response.data.items,
              });
            }
          })
          .catch((err) => {
            setMenuGovernanca({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getMenuGovernanca();
    } else {
      async function getMenuGovernanca() {
        await governancaApi
          .get("menu-governanca", {
            params: {
              id: process.env.REACT_APP_ID_MENU_GOV,
              expand: "filho",
              id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
              isNullField: "id_pai",
              sort: "ordem",
            },
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setMenuGovernanca({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setMenuGovernanca({
                loading: false,
                error: false,
                payload: response.data.items[0],
              });
            }
          })
          .catch((err) => {
            setMenuGovernanca({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getMenuGovernanca();
    }
  }, []);

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const [openSubMenu, setOpenSubMenu] = useState(false);
  const handleClickSubMenu = (target, index) => {
    setOpenSubMenu({ [index]: !openSubMenu[index] });
    setOpenGovernanca(false);
  };
  const [openGovernanca, setOpenGovernanca] = useState(false);
  const handleClickGovernanca = () => {
    setOpenGovernanca(!openGovernanca);
    setOpenSubMenu(false);
  };

  let history = useHistory();

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchTerm) {
        history.push(`/pesquisa?q=${searchTerm}`);
      } else {
        history.push("/pesquisa");
      }
    }
  };

  const executeSearch = (e) => {
    e.preventDefault();
    if (searchTerm !== "") {
      history.push(`/pesquisa?q=${searchTerm}`);
    }
    setSearchTerm("");
  };

  const [partidasDestaque, setPartidasDestaque] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setPartidasDestaque({
      loading: true,
      error: false,
      payload: [],
    });

    async function getPartidasDestaque() {
      await ConfedApi.get("evento-partida", {
        params: {
          forcewhere: `idEvento.id_estabelecimento:${process.env.REACT_APP_ID_ESTABELECIMENTO}`,
          forcejoin: "idEvento,casa,visitante",
          expand: "idEvento,casa,visitante",
          sort: "-data",
          lessField: "data",
          lessValue: `${moment(new Date()).format().substr(0, 10)} 23:59:59`,
          moreField: "data",
          moreValue: `${moment(new Date()).format().substr(0, 10)} 00:00:00`,
          pageSize: 4,
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setPartidasDestaque({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setPartidasDestaque({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setPartidasDestaque({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getPartidasDestaque();
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const nextStep = () => {
    if (activeStep < partidasDestaque.payload.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };
  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const [openSearchSM, setOpenSearchSM] = useState(false);

  const [fluidContainer, setFluidContainer] = useState(false);
  useEffect(() => {
    if (process.env.REACT_APP_ESTABELECIMENTO === "CBBC") {
      setFluidContainer(true);
    }
  }, []);

  const createLinkCBBC = (menuItem) => {
    return menuItem.url ? (
      <React.Fragment>
        {menuItem.url.includes("//") ? (
          <a href={menuItem.url} target="_blank" rel="noreferrer">
            {menuItem.titulo}
          </a>
        ) : (
          <React.Fragment>
            {menuItem.url.includes("/governanca-cbbc/") ? (
              <Link
                to={`/governanca-cbbc/${menuItem.id}/${createSlug(
                  menuItem.titulo
                )}`}
              >
                {menuItem.titulo}
              </Link>
            ) : (
              <Link to={menuItem.url}>{menuItem.titulo}</Link>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    ) : (
      <Link
        to={`/cbbc/${menuItem.id}/${
          menuItem.url ? menuItem.url : createSlug(menuItem.titulo)
        }`}
      >
        {menuItem.titulo}
      </Link>
    );
  };

  const searchForm = () => {
    return (
      <div className="header-search-form">
        <form id="mobile-search-form" className="search-form">
          <input
            type="text"
            className="form-control header-mobile__search-control"
            placeholder="Pesquisar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleSearchKeyDown}
          />
          <button
            type="submit"
            onClick={executeSearch}
            className="header-mobile__search-submit"
          >
            <i className="fas fa-search"></i>
          </button>
        </form>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div
        className={`header-mobile clearfix ${
          openSearchSM ? "header-mobile--expanded" : ""
        }`}
        id="header-mobile"
      >
        <div className="header-mobile__logo">
          <Link to="/">
            <img
              src={siteMeta.logoMobile}
              alt={siteMeta.siteTitle}
              className="header-mobile__logo-img"
            />
          </Link>
        </div>
        <div className="header-mobile__inner">
          <IconButton
            onClick={() => handleOpenDrawer()}
            id="header-mobile__toggle"
            className="burger-menu-icon"
          >
            <span className="burger-menu-icon__line"></span>
          </IconButton>
          <span
            onClick={() => setOpenSearchSM(!openSearchSM)}
            className={`header-mobile__search-icon ${
              openSearchSM ? "header-mobile__search-icon--close" : ""
            }`}
            id="header-mobile__search-icon"
          ></span>
        </div>
        <div className="header-search-form">
          <form action="#" id="mobile-search-form" className="search-form">
            <input
              type="text"
              className="form-control header-mobile__search-control"
              placeholder="Pesquisar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleSearchKeyDown}
            />
            <button
              type="submit"
              onClick={executeSearch}
              className="header-mobile__search-submit"
            >
              <i className="fas fa-search"></i>
            </button>
          </form>
        </div>
      </div>

      <header className="header header--layout-1">
        <div className="header__top-bar clearfix">
          <div
            className={`${
              fluidContainer ? "container-fluid" : "container"
            } d-flex align-items-center justify-content-between`}
          >
            {partidasDestaque.loading || partidasDestaque.error ? (
              <div className="header__top-bar-inner"></div>
            ) : (
              <div className="header__top-bar-inner">
                <div className="slide-wrapper">
                  <div className="slide-arrows">
                    <button
                      onClick={() => prevStep()}
                      className="btn btn-xs btn-arrow"
                      aria-label="Previous"
                      type="button"
                    >
                      <i className="fas fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => nextStep()}
                      className="btn btn-xs btn-arrow"
                      aria-label="Next"
                      type="button"
                    >
                      <i className="fas fa-angle-right"></i>
                    </button>
                  </div>
                  {partidasDestaque.payload.length ? (
                    <AutoPlaySwipeableViews
                      axis="x"
                      interval={9000}
                      index={activeStep}
                      onChangeIndex={handleStepChange}
                    >
                      {partidasDestaque.payload.map((item, key) => (
                        <div key={key}>
                          <div className="slide-content">
                            <Link
                              to={`/evento/${item.id_evento}/${createSlug(
                                item.idEvento.descricao
                              )}/partida/${item.id}/`}
                            >
                              <Typography noWrap>
                                {convertDateTimeAbrev(item.data)}
                                <span className="red">{"//"}</span>

                                {item.casa.sigla_evento}
                                <span className="vs">X</span>
                                {item.visitante.sigla_evento}
                              </Typography>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </AutoPlaySwipeableViews>
                  ) : null}
                </div>
              </div>
            )}
            <div className="header__top-bar-inner links-redes-sociais">
              <div className="info-block__item info-block__item--nopadding">
                <ul className="social-links">
                  {siteMeta.redesSociais.map((item, key) => (
                    <li className="social-links__item" key={key}>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                        className="social-links__link"
                      >
                        <i className={`fab fa-${item.name}`}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="header__secondary">
          <div
            className={`${
              fluidContainer ? "container-fluid" : "container"
            } d-flex align-items-center`}
          >
            {process.env.REACT_APP_ESTABELECIMENTO === "CBFutsal" ? (
              <div className="info-block logos-lpf info-block--header mr-auto">
                <Link
                  to={`/eventos/9/${createSlug(
                    "Liga Paulista do Interior de Futsal"
                  )}`}
                >
                  <img src={lpf_interior} width="42" height="65" alt="" />
                </Link>
                <Link
                  to={`/eventos/11/${createSlug(
                    "Liga Paulista Feminina de Futsal"
                  )}`}
                >
                  <img src={lpf_feminina} width="38" height="65" alt="" />
                </Link>
                <Link
                  to={`/eventos/13/${createSlug(
                    "Liga Paulista SUB20 de Futsal"
                  )}`}
                >
                  <img src={lpf_sub20} width="38" height="65" alt="" />
                </Link>
                <Link
                  to={`/eventos/15/${createSlug(
                    "Liga Paulista Junior de Futsal"
                  )}`}
                >
                  <img src={lpf_junior} width="38" height="65" alt="" />
                </Link>
                <Link
                  to={`/eventos/17/${createSlug(
                    "Liga Paulista Kids de Futsal"
                  )}`}
                >
                  <img src={lpf_kids} width="38" height="65" alt="" />
                </Link>
              </div>
            ) : (
              ""
            )}
            {process.env.REACT_APP_ESTABELECIMENTO === "CBHB" ? (
              <div className="info-block info-block--header mr-auto">
                {/* <Link to={`/eventos/9/${createSlug('liga nacional de handebol')}`}> */}
                <img src={cbhb_liga_nacional} height="100" width="85" alt="" />
                {/* </Link> */}
              </div>
            ) : (
              ""
            )}
            {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
              <Link to="/">
                <Typography
                  variant="h6"
                  component="h1"
                  align="center"
                  color="white"
                  className="text-header"
                >
                  Confederação Brasileira <br /> de Basquetebol em
                  <br /> Cadeira de Rodas
                </Typography>
              </Link>
            ) : (
              ""
            )}
            {searchForm()}
          </div>
        </div>

        <div className="header__primary">
          <div
            className={`${fluidContainer ? "container-fluid" : "container"}`}
          >
            <div className="header__primary-inner">
              <div className="header-logo">
                <Link to="/">
                  <img
                    src={siteMeta.logo}
                    alt={siteMeta.siteTitle}
                    className="header-logo__img"
                  />
                </Link>
              </div>
              <nav className="main-nav clearfix">
                <Hidden mdDown>
                  <ul className="main-nav__list">
                    <div className="header-mobile__logo">
                      <span
                        className="main-nav__back"
                        onClick={() => handleOpenDrawer()}
                      ></span>
                      <Link to="/">
                        <img
                          src={siteMeta.logo}
                          alt={siteMeta.siteTitle}
                          className="header-mobile__logo-img"
                        />
                      </Link>
                    </div>
                    {process.env.REACT_APP_ESTABELECIMENTO !== "CBBC" && (
                      <li className={splitLocation[1] === "" ? "active" : ""}>
                        <Link to="/">Home</Link>
                      </li>
                    )}
                    {/* menu cbbc*/}
                    {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
                      <React.Fragment>
                        {menuGovernanca.loading || menuGovernanca.error
                          ? null
                          : menuGovernanca.payload.map((item, key) => (
                              <li
                                className={`${
                                  item.filho.length > 0 && "has-children"
                                }`}
                                key={key}
                              >
                                {item.filho.length > 0 ? (
                                  <React.Fragment>
                                    <span className="main-nav__toggle"></span>
                                    <a href="#!">{item.titulo}</a>
                                    <ul className="main-nav__sub" key={key}>
                                      {_.orderBy(
                                        item.filho,
                                        ["ordem"],
                                        ["asc"]
                                      ).map((filho, key) => (
                                        <li
                                          className={
                                            splitLocation[2] === ""
                                              ? "active"
                                              : ""
                                          }
                                          key={key}
                                        >
                                          {createLinkCBBC(filho)}
                                        </li>
                                      ))}
                                    </ul>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {item.url.includes("/") ? (
                                      <React.Fragment>
                                        {item.url.includes("//") ? (
                                          <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {item.titulo}
                                          </a>
                                        ) : (
                                          <Link to={item.url}>
                                            {item.titulo}
                                          </Link>
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      <Link
                                        to={`/cbbc/${item.id}/${
                                          item.url
                                            ? item.url
                                            : createSlug(item.titulo)
                                        }`}
                                      >
                                        {item.titulo}
                                      </Link>
                                    )}
                                  </React.Fragment>
                                )}
                              </li>
                            ))}
                      </React.Fragment>
                    ) : (
                      <li className="has-children">
                        <span className="main-nav__toggle"></span>
                        <a href="#!">Governança</a>
                        <div className="main-nav__megamenu clearfix flex-wrap">
                          {menuGovernanca.loading ||
                          menuGovernanca.error ||
                          !menuGovernanca.payload.filho
                            ? ""
                            : menuGovernanca.payload.filho.map((item, key) => (
                                <ul
                                  className="col-lg-2 col-md-3 col-12 main-nav__ul"
                                  key={key}
                                >
                                  <li
                                    className="main-nav__title"
                                    style={{
                                      whiteSpace: "normal",
                                      lineHeight: 1.2,
                                    }}
                                  >
                                    {item.titulo}
                                  </li>
                                  {item.filho
                                    ? item.filho.map((item, key) => (
                                        <li key={key}>
                                          <Link
                                            to={`/governanca/${item.id}/${
                                              item.url
                                                ? item.url
                                                : createSlug(item.titulo)
                                            }`}
                                          >
                                            {item.titulo}
                                          </Link>
                                        </li>
                                      ))
                                    : ""}
                                </ul>
                              ))}
                        </div>
                      </li>
                    )}
                    {siteMenu.map((item, key) => (
                      <React.Fragment key={key}>
                        <li
                          className={`${item.filho ? "has-children" : ""} ${
                            key === 0 ? "left-center" : ""
                          } ${
                            splitLocation[1] === item.url.replace("/", "")
                              ? "active"
                              : ""
                          }`}
                          key={key}
                        >
                          {item.filho ? (
                            <React.Fragment>
                              <span className="main-nav__toggle"></span>
                              <Link to={item.url}>{item.label}</Link>
                              <ul className="main-nav__sub" key={key}>
                                {item.filho.map((filho, key) => (
                                  <li
                                    className={
                                      splitLocation[2] === "" ? "active" : ""
                                    }
                                    key={key}
                                  >
                                    {filho.url.includes("//") ? (
                                      <a
                                        href={filho.url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {filho.label}
                                      </a>
                                    ) : (
                                      <Link to={filho.url}>{filho.label}</Link>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {item.url.includes("//") ? (
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.label}
                                </a>
                              ) : (
                                <Link to={item.url}>{item.label}</Link>
                              )}
                            </React.Fragment>
                          )}
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                </Hidden>
                <Hidden mdUp>
                  <ul className="main-nav__list">
                    <div className="header-mobile__logo">
                      <span
                        className="main-nav__back"
                        onClick={() => handleOpenDrawer()}
                      ></span>
                      <Link to="/" onClick={() => handleOpenDrawer()}>
                        <img
                          src={siteMeta.logo}
                          alt={siteMeta.siteTitle}
                          className="header-mobile__logo-img"
                        />
                      </Link>
                    </div>
                    {/* menu dinâmico */}
                    {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
                      ""
                    ) : (
                      <li className={splitLocation[1] === "" ? "active" : ""}>
                        <Link to="/" onClick={() => handleOpenDrawer()}>
                          Home
                        </Link>
                      </li>
                    )}
                    {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
                      <React.Fragment>
                        {menuGovernanca.loading || menuGovernanca.error
                          ? ""
                          : menuGovernanca.payload.map((item, key) => (
                              <li
                                key={key}
                                className={`${
                                  item.filho ? "has-children" : ""
                                } ${
                                  splitLocation[1] === item.url ||
                                  openSubMenu[key]
                                    ? "active"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handleClickSubMenu(e.currentTarget, key)
                                }
                              >
                                {item.filho && item.filho.length ? (
                                  <React.Fragment>
                                    <a href="#!">{item.titulo}</a>
                                    <span
                                      className={`main-nav__toggle ${
                                        openSubMenu[key]
                                          ? "main-nav__toggle--rotate"
                                          : ""
                                      }`}
                                    ></span>
                                    <Collapse
                                      in={openSubMenu[key]}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <ul
                                        className="main-nav__sub d-block"
                                        style={{ top: 0 }}
                                      >
                                        {_.orderBy(
                                          item.filho,
                                          ["ordem"],
                                          ["asc"]
                                        ).map((filho, key) => (
                                          <li
                                            key={key}
                                            onClick={() => handleOpenDrawer()}
                                          >
                                            {createLinkCBBC(filho)}
                                          </li>
                                        ))}
                                      </ul>
                                    </Collapse>
                                  </React.Fragment>
                                ) : (
                                  <>
                                    {item.url.includes("//") ? (
                                      <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.titulo}
                                      </a>
                                    ) : (
                                      <Link
                                        to={item.url}
                                        onClick={() => handleOpenDrawer()}
                                      >
                                        {item.titulo}
                                      </Link>
                                    )}
                                  </>
                                )}
                              </li>
                            ))}
                      </React.Fragment>
                    ) : (
                      <li
                        className={`has-children ${
                          openGovernanca ? "active" : ""
                        }`}
                        onClick={() => handleClickGovernanca()}
                      >
                        <span
                          className={`main-nav__toggle ${
                            openGovernanca ? "main-nav__toggle--rotate" : ""
                          }`}
                        ></span>
                        <a href="#!">Governança</a>
                        <Collapse
                          in={openGovernanca}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div className="main-nav__megamenu clearfix d-block d-md-flex">
                            {menuGovernanca.loading ||
                            menuGovernanca.error ||
                            !menuGovernanca.payload.filho
                              ? ""
                              : menuGovernanca.payload.filho.map(
                                  (item, key) => (
                                    <ul
                                      className="col-lg-2 col-md-3 col-12 main-nav__ul"
                                      key={key}
                                    >
                                      <li
                                        className="main-nav__title"
                                        style={{
                                          whiteSpace: "normal",
                                          lineHeight: 1.2,
                                        }}
                                      >
                                        {item.titulo}
                                      </li>
                                      {item.filho
                                        ? item.filho.map((item, key) => (
                                            <li key={key}>
                                              <Link
                                                to={`/governanca/${item.id}/${
                                                  item.url
                                                    ? item.url
                                                    : createSlug(item.titulo)
                                                }`}
                                                key={key}
                                                onClick={() =>
                                                  handleOpenDrawer()
                                                }
                                              >
                                                {item.titulo}
                                              </Link>
                                            </li>
                                          ))
                                        : ""}
                                    </ul>
                                  )
                                )}
                          </div>
                        </Collapse>
                      </li>
                    )}

                    {/* menu estático */}
                    {siteMenu.map((item, key) => (
                      <li
                        key={key}
                        className={`${item.filho ? "has-children" : ""} ${
                          splitLocation[1] === item.url ||
                          openSubMenu[key + 100]
                            ? "active"
                            : ""
                        }`}
                        onClick={(e) =>
                          handleClickSubMenu(e.currentTarget, key + 100)
                        }
                      >
                        {item.filho ? (
                          <React.Fragment>
                            <a href="#!">{item.label}</a>
                            <span
                              className={`main-nav__toggle ${
                                openSubMenu[key + 100]
                                  ? "main-nav__toggle--rotate"
                                  : ""
                              }`}
                            ></span>
                            <Collapse
                              in={openSubMenu[key + 100]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <ul
                                className="main-nav__sub d-block"
                                style={{ top: 0 }}
                              >
                                {item.filho.map((filho, key) => (
                                  <li key={key}>
                                    <Link
                                      to={filho.url}
                                      onClick={() => handleOpenDrawer()}
                                    >
                                      {filho.label}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </Collapse>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {item.url.includes("//") ? (
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.label}
                              </a>
                            ) : (
                              <Link
                                to={item.url}
                                onClick={() => handleOpenDrawer()}
                              >
                                {item.label}
                              </Link>
                            )}
                          </React.Fragment>
                        )}
                      </li>
                    ))}
                  </ul>
                </Hidden>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Navbar;
